/* CSS Document */
body, p, h1, h2, h3, h4, h5, h6, span, ul, ol, li, dl, dt, dd, form {
      padding: 0;
      margin: 0; }
    
    html, body {
      padding: 0;
      margin: 0;
      height: auto;
      background: #eff2f8;
  } 
  html {
        scroll-behavior: smooth;
  }
  
    input {
      outline: none; }
    
    textarea {
      border: none;
      outline: none;
      overflow: auto;
      resize: none; }
    
    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none; }
    
    select::-ms-expand {
      display: none; }
    
    input.button {
      border: none;
      outline: none; }
    
    input::-webkit-input-placeholder {
      opacity: 1; }
    
    input::-moz-placeholder {
      opacity: 1; }
    
    input:-ms-input-placeholder {
      opacity: 1; }
    
    input:-moz-placeholder {
      opacity: 1; }
    
    input[type=checkbox] {
      border: none;
      outline: none; }
    
    img {
      outline: none;
      border: none;
      max-width: 100%;
      user-select: none;
      -webkit-user-select: none; }
    
    li {
      list-style: none; }
    
    a, a:active, a:hover {
      text-decoration: none;
      outline: none !important;
      user-select: none;
      -webkit-user-select: none; }
    
    a:focus {
      outline: none;
      text-decoration: none; }
    
    * {
      box-sizing: border-box; }
    
    button, a, input[type="submit"] {
      -o-transition: all .5s ease-out, all 0.5s ease-in;
      -ms-transition: all .5s ease-out, all 0.5s ease-in;
      -moz-transition: all .5s ease-out, all 0.5s ease-in;
      -webkit-transition: all .5s ease-out, all 0.5s ease-in;
      transition: all .5s ease-out, all 0.5s ease-in; }
    
    :not(output):-moz-ui-invalid {
      box-shadow: inset; }
    
    a:hover, a, a:focus {
      color: inherit; }
    
    a, a:hover, a:focus, button, button:hover, button:focus {
      outline: none; }
    
    div:focus {
      outline: none; }
  
._fl {
    float: left; }
  
  ._fr {
    float: right; }

.container {
	max-width: 1610px!important;
	width: 100%;
	padding: 0!important;
	margin: auto!important;
}

.textoverflowfix {
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .table th, .table td{vertical-align: middle;}
  .table tbody tr{transition: all ease 0.75s;}  
  .table tbody tr:nth-child(even){background-color: #f3fffd;}
  .table tbody tr td:first-child {border-radius: 10px 0 0 10px;}
  .table tbody tr td:last-child {border-radius: 0 10px 10px 0;}
  .table tbody tr:hover{ background: #e6fffe; }  

input[type="checkbox"], input[type="radio"]  {display: none;}
input[type="checkbox"] + *::before, input[type="radio"] + *::before {content: ""; display: flex; justify-content: center;  align-items: center;  vertical-align: middle;  width: 1rem;  height: 1rem;  margin-right: 0.3rem;  border-radius: 10%;  border-style: solid;  border-width: 0.1rem;  border-color: #9CC2C1;  flex-shrink: 0;  margin-top: 0px; transition: all ease 0.75s; border-radius: 6px;}
input[type="checkbox"]:checked + *::before, input[type="radio"]:checked + *::before {  content: "✓";  color: #fff;  text-align: center;  background: #1B9793;  border-color: #1B9793;  }
input[type="checkbox"] + *, input[type="radio"] + * {  display: flex; position: relative;}
input[type="radio"] + *::before, input[type="radio"]:checked + *::before{border-radius: 50%;}

textarea.replytextarea {
    min-height: 58px;
}
button.gpt_btn.btn_loading {
    position: relative;
}
.truncate-table-cell {
    max-width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*--- Fonts ---*/
@font-face {
      font-family: "Poppins-Bold";
      src: url("../fonts/Poppins-Bold.woff") format("woff");
      font-weight: normal;
      font-style: normal;
}

@font-face {
      font-family: "Poppins-Light";
      src: url("../fonts/Poppins-Light.woff") format("woff");
      font-weight: normal;
      font-style: normal;
}

@font-face {
      font-family: "Poppins-Medium";
      src: url("../fonts/Poppins-Medium.woff") format("woff");
      font-weight: normal;
      font-style: normal;
}

@font-face {
      font-family: "Poppins-Regular";
      src: url("../fonts/Poppins-Regular.woff") format("woff");
      font-weight: normal;
      font-style: normal;
}

@font-face {
      font-family: "Poppins-SemiBold";
      src: url("../fonts/Poppins-SemiBold.woff") format("woff");
      font-weight: normal;
      font-style: normal;
}
/*--- Fonts ---*/

:root {
    --pb: Poppins-Bold;
    --pl: Poppins-Light;
    --pm: Poppins-Medium;
    --pr: Poppins-Regular;
    --ps: Poppins-SemiBold;
    --green: #0ABAB5;
    --lgreen: #49FFFA;
    --white: #fff;
    --black: #1E1E1E;
    --toastify-color-success: #0abab5 !important;
}

body {
    width: 100%;
    min-height: 100vh;
    background: url(../images/body_bg.png) no-repeat center;
    background-size: cover;
    padding: 15px;
    background-attachment: fixed;
    margin-top: -1px!important;
}


/*LOGIN PAGE*/
.login_page {
    width: 100%;
    min-height: 96.8vh;
    background: url(../images/login_bg.png) no-repeat center;
    background-size: cover;
    border-radius: 50px;
    padding: 100px;
    box-shadow: 0 0 10px 0 #292929;
}

.login_page .flex {
    justify-content: flex-start;
}

.login_page .text {
    width: 54%;
}
.login_page .text h1 {
    font: 85px/100px var(--pm);
    color: var(--lgreen);
}
.login_page .text h1 span {
    display: block;
    font: 130px/150px var(--pb);
    color: var(--white);
    text-transform: uppercase;
}
.login_page .text p {
    font: 30px/60px var(--pm);
    color: var(--white);
}

.login_page .log_form {
    width: 100%;
    max-width: 662px;
    background: var(--white);
    border-radius: 30px;
    text-align: center;
    padding: 125px 0;
}
.log_form .logo {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
}
.log_form form h3 {
    font: 35px/35px var(--pb);
    color: var(--black);
    margin-bottom: 55px;
    text-transform: uppercase;
}
.log_form form .l_frm {
    width: 100%;
    max-width: 325px;
    margin: 0 auto;
    display: block;
    position: relative;
}

.log_form form .l_frm:not(:last-child) {
    margin-bottom: 20px;
}

.log_form form .l_frm label {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    font: 14px/21px var(--pr);
    color: #7AAAA9;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s all ease-in-out;
    background: var(--white);
    padding: 0 10px;
    z-index: 1;
}

.log_form form .l_frm input {
    width: 100%;
    border: 1px solid #9CC2C1;
    border-radius: 10px;
    height: 48px;
    background: var(--white);
    padding: 10px 25px;
    color: #7AAAA9;
} 

.log_form form .l_frm input::placeholder {
    color: transparent;
}

.log_form form .l_frm input:valid, .log_form form .l_frm input:focus, .log_form form .l_frm input:not(:placeholder-shown) {
    border-color: var(--green);
    background: var(--white)!important;
}

.log_form form .l_frm input:valid ~ label, .log_form form .l_frm input:focus ~ label, .log_form form .l_frm input:not(:placeholder-shown) ~ label {
    top: 0;
    color: var(--green);
}

.log_form form .l_frm button {
    width: 100%;
    text-align: center;
    background: var(--green);
    color: var(--white);
    font: 16px/24px var(--pb);
    height: 48px;
    border: 2px solid #9CC2C1;
    border-radius: 10px;
    position: relative;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--green);
  }
}

.log_form form .l_frm button:hover {
    transition: 0.5s;
    border-color: var(--green);
    background: transparent;
    color: var(--green);
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
}

/*LOGIN PAGE*/


/*DASHBOARD PAGE*/
.gene-layout {
    width: 100%;
    height: 100%;
    max-height: 96.8vh;
    min-height: 96.8vh;
    background: url(../images/dash_bg.png) no-repeat center;     
    background-size: cover;   
    border: 1px solid #095553;
    position: relative;
/*    background: rgba(36, 108, 106, 0.65);*/
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}

.sidebar {
    position: absolute;
    left: 0;
    top: 0; 
    z-index: 999;
    top: 15px;
    width: 315px;
    overflow: hidden;
    height: 100%;
    transition: all .3s ease-in-out;  
    padding: 10px 45px 22px 15px;   
}
.sidebar .logo {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.sidebar .logo img {
    max-width: 203.85px;
    object-fit: contain;
    object-position: center;
    margin: auto;
}
.manus, .menu_list {
    width: 100%;
}
.menu_list ul li:not(:last-child) {
    margin-bottom: 10px;
}
.menu_list ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font: 16px/24px var(--pm);
    color: var(--white);
    text-decoration: none;
    padding: 6.71px 15px;
    transition: 0.5s all ease-in-out;
    border: 1px solid transparent;
    border-radius: 10px;
    position: relative;
}
.menu_list ul {
    padding: 0;
    margin: 0;
}

.menu_list ul li {
    transition: 0.5s all ease-in-out;
}
.menu_list ul li a i {
    margin-right: 10px;
}
.menu_list ul li a.active {
    background: rgba(34, 255, 248, 0.4);
    border-color: #22A9A5;
    box-shadow: 0px 2px 5px 1px rgba(10, 186, 181, 0.26);
    backdrop-filter: blur(2px);
}
.menu_list ul li a:hover {
    box-shadow: 0px 2px 5px 1px rgba(37, 64, 63, 0.26);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background: #1d6c6a;
}

.menu_list ul li a > .drop {
    margin: 0;
    position: absolute;
    right: 15px;
    z-index: 1;
    transition: 0.5s all ease-in-out;
}

.menu_list ul li a.active > .drop {
    transform: rotate(180deg);
    filter: brightness(0) invert(1);
}
.menu_list ul li ul.sub_menu {
    padding-left: 35px;
    display: none;
    transition: 0.5s all ease-in-out;
    padding-top: 10px;
}
/*
.menu_list ul li ul.sub_menu li {
    margin: 0
}
*/
.menu_list ul li a.active + ul.sub_menu {
    display: block;
}
.das_wrapper {
    width: calc(100% - 315px);
    margin-left: auto;
    padding: 25px 25px 25px 0;
    height: 100%;
    max-height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);
    overflow: hidden;
}
.dashboard {
    width: 100%;
    position: relative;
}
.dashboard header {
    width: 100%;
    position: sticky;
    top: 0;
}
.dashboard header .flex {
    align-items: flex-start;
}
.dashboard header .lft h4 {
    font: 24px/36px var(--pb);
    color: #8BFFFC;   
}
.dashboard header .lft p {
    font: 14px/21px var(--pm);
    color: var(--white);
}
.dashboard header .rft {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dashboard header .rft img {
    width: 48px;
    margin-left: 15px;
}
.dashboard header .rft .txt h5 {
    font: 16px/24px var(--pm);
    color: var(--white);
    margin: 0;
}
.dashboard header .rft .txt a {
    font: 12px/18px var(--pm);
    color: #8BFFFC;
    text-decoration: none;
}
.dashboard header .rft .txt a:hover{ color: #fff; text-decoration: underline;}
.dashboard header .rft .txt {
    text-align: right;
}
.main_content {
    width: 100%;
    padding: 15px;
    border-radius: 30px;
    background: var(--white);
    max-height: calc(100vh - 175px);
    min-height:  calc(100vh - 175px);
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    margin-top: 15px;
}
.main_content::-webkit-scrollbar {
    width: 0px;
}
.main_content::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.main_content::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d; 
}
.innermain{max-height: calc(100vh - 150px);min-height:  calc(100vh - 150px);}

.dash_box .box {
    width: 100%;
    border: 1px solid #0ABAB5;
    border-radius: 15px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s all ease-in-out;
}
.dash_box .box:hover {
    transform: translateY(-4px);
    box-shadow: 0 0 10px 0 #e1e1e1;
    background: var(--green);
}
.dash_box .box > .img {
    width: 100px;
    transition: 0.5s all ease-in-out;
}
.dash_box .box > .txt {
    width: calc(100% - 100px);
    padding-right: 15px;
    text-align: left;
}
.dash_box .box > .txt p {
    margin: 0;
    font: 14px/21px var(--pm);
    color: var(--black);
    transition: 0.5s all ease-in-out;
}
.dash_box .box > .txt h2, .post_history .targettxts h4 {
    font: 45px/45px var(--pb);
    color: #0ABAB5;
    margin: 15px 0 0;
    transition: 0.5s all ease-in-out;
}
.post_history .targettxts h4{ 
    font: 23px/28px var(--pm); 
    color: var(--black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
    text-align: center;
}
.onlinetxt{ background: #0ABAB5; width: 20px; height: 20px; border-radius: 50%; display: inline-block; margin-right: 5px; border:2px solid #fff}
.dash_box.targettxts .txt {width: 100% !important; padding: 0px;}
.dash_box.targettxts .box > .txt p{min-height: auto; display: flex; align-items: center; margin-top: 15px;justify-content: center;}
.dash_box .box:hover .txt p, .dash_box .box:hover .txt h2, .post_history  .targettxts  .box:hover h4 {
    color: var(--white);
}
.dash_box .box:hover > .img {
    background: var(--white);
    border-radius: 10px;
    padding: 10px;
}
.post_history {
    width: 100%;   
    position: relative;
}
.mt-20px{margin-top: 20px;}
.mt-30px{margin-top: 30px;}
.post_history h4 {
    font: 20px/30px var(--ps);
    color:  #333333;
}
.post_history .table thead tr th {
    font: 14px/21px var(--pm);
    color: #7b9796;
    border: none;
}
.post_history .table thead tr th:not(:first-child), .post_history .table tbody tr td:not(:first-child) {
    text-align: center;
}
.post_history .table {
    border-collapse: separate;
    border-spacing: 0 5px;
    padding-bottom: 50px;
}
.post_history .table tbody tr td {
    font: 14px/21px var(--pm);
    color: #333333;
    border: 1px solid #B9E3E2;
}
.post_history .table tbody tr td:not(:last-child) {
    border-right-color: transparent;
}
.post_history .table tbody tr td:not(:first-child) {
    border-left-color: transparent;
}
.post_history .table tbody tr td:first-child {
    border-radius: 10px 0 0 10px;
}
.post_history .table tbody tr td:last-child {
    border-radius: 0 10px 10px 0;
}
.post_history .table tbody tr td .media_name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.post_history .table tbody tr td .media_name span {
    color: #000;
}
.media_name i {
    width: 24px;
    margin-right: 15px;
}
.posthistoryloader {
    min-height: 150px;
}
/*DASHBOARD PAGE*/

/*RECEIVED ACTIVITY*/
.dashboard header .lft h3 {
    font: 30px/45px var(--pb);
    color: #8BFFFC;
    text-transform: uppercase;
}
.rec_box {
    width: 100%;
    border: 1px solid #9CC2C1;
    border-radius: 20px;
    padding: 15px;
}
.rec_box .boxs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
}
.rec_box .boxs .meida_icons {
    width: 30px;
}
.rec_box .boxs .media_txt {
    width: calc(100% - 50px);
    margin-left: 20px;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.rec_box .boxs._fb .media_txt {
    background: #EBF1FF;
    border: 1px solid #C7D9FF;
    border-radius: 16px;
}
.rec_box .boxs .media_txt .icon {
    width: 36px;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 5px 0 #e1e1e1;
    padding: 5px;
}
.rec_box .boxs .media_txt ._txt {
    width: calc(100% - 36px);
    padding-left: 10px;
}
.rec_box .boxs .media_txt ._txt h4 {
    font: 14px/20px var(--ps);
    color: #185351;
    margin: 0;
}
.rec_box .boxs .media_txt ._txt span {
    font: 12px/18px var(--pm);
    color: #4D4D4D;
}
.rec_box .boxs .media_txt ._txt p {
    font: 14px/20px var(--ps);
    color: #333333;
    margin: 10px 0 20px;
    word-break: break-word;
}
.rec_box .boxs .media_txt ._txt ul {
    padding: 0;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.rec_box .boxs .media_txt ._txt ul li:not(:last-child) {
    margin-right: 10px;
}
.rec_box .boxs .media_txt ._txt ul li {
    font: 14px/21px var(--pm);
}
.rec_box .boxs._fb .media_txt ._txt ul li {
    color: #3B5998;
}
.rec_box .boxs .media_txt ._txt button.gpt_btn {
    margin-left: auto;
    width: auto;
    display: block;
    max-width: 392px;
    background: #FFFFFF;
    border: 1px solid #22A9A5;
    box-shadow: 0px 2px 5px 1px rgba(10, 186, 181, 0.26);    
    border-radius: 10px;
    font: 16px/24px var(--pb);
    color: #22A9A5;
    padding: 4.5px 20px;    
}
.rec_box .boxs .media_txt ._txt button.gpt_btn img {
    background: #fff;
    border-radius: 100%;
}
.rec_box .boxs .media_txt ._txt button.gpt_btn span {
    margin-left: 10px;
    font: 16px/24px var(--pb);
    color: #22A9A5;
    transition: 0.5s all ease-in-out;
}
.rec_box .boxs .media_txt ._txt button.gpt_btn:hover {
    background: var(--green);
}
.rec_box .boxs .media_txt ._txt button.gpt_btn:hover span {
    color: #fff;
}
.rec_box .boxs._twt .media_txt {
    background: #e9f9ff;
    border: 1px solid #c4efff;
    border-radius: 16px;
}
.rec_box .boxs._insta .media_txt {
    background: #FFFAFA;
    border: 1px solid #FFCAC2;
    border-radius: 16px;
}
.rec_box .boxs .msg_box {
    width: 100%;
}
.rec_box .boxs .msg_box .show_msg {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 15px;
    margin-bottom: 15px;
}

.rec_box .boxs .msg_box .show_msg p {
    font: 14px/20px var(--ps);
    color: #333333;
}
.type_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.type_box button.send_btn {
    background: #0ABAB5;
    border: 1px solid #22A9A5;
    box-shadow: 0px 2px 5px 1px rgba(10, 186, 181, 0.26);
    backdrop-filter: blur(2px);
    border-radius: 0px 16px 16px 0px;
    width: 60px;
    height: 60px;
}
.type_box > div {
    width: calc(100% - 60px);
}
.type_box > div > div {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px 0px 0px 16px;
    padding: 11px 10px;
    margin-top: -0.5px;
}
.type_box > div > div .css-3w2yfm-ValueContainer {
    padding: 0;
}
.type_box > div > div .css-3w2yfm-ValueContainer > div > div {
    background: #E1FFFE;
    border: 1px solid #0ABAB5;
    border-radius: 10px;
    overflow: hidden;
}
.type_box > div > div .css-3w2yfm-ValueContainer > div > div > div {
    font: 14px/24px var(--pm);
    color: #095553;
}
.type_box > div > div .css-3w2yfm-ValueContainer > div {
    width: auto!important;
}
button.menu {
    width: 40px;
    height: 40px;
    background: #fff;
    border: 1px solid var(--green);
    border-radius: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 35px;
    top: 30px;
    transition: 0.5s all ease-in-out;
}
button.close {
    width: 35px;
    height: 35px;
    background: #fff;
    border: 1px solid var(--green);
    border-radius: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    padding: 10px;
}
/*RECEIVED ACTIVITY*/

/*CREATE POST*/
.create_post .nav-tabs {
    border-bottom: none;
    margin-bottom: 0!important;
}
.create_post .nav-tabs li button {
    font: 14px/21px var(--pm);
    color: #000000;
    text-transform: uppercase;
    padding: 15px 20px 15px 40px;
}
.create_post .nav-tabs li:first-child button {
    background: url(../images/twitter.png) no-repeat left 10px center;
}
.create_post .nav-tabs li:nth-child(2) button {
    background: url(../images/facebook.png) no-repeat left 10px center;
}
.create_post .nav-tabs li:last-child button {
    background: url(../images/instagram.png) no-repeat left 10px center;
}
.create_post .nav-tabs li button.active, .create_post .nav-tabs li button:hover {
    border: 1px solid #9CC2C1;
    border-radius: 16px 16px 0px 0px;
    border-bottom-color: transparent;
    background-color: #fff;
}
.create_post .tab-content {
    background: #FFFFFF;
    border: 0.1px solid #9CC2C1;
    border-radius: 0px 16px 16px 16px;
    min-height: 75vh;
    margin-top: -1px;
    padding: 30px;
}
.create_post .tab-content .tab-cnt {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #9CC2C1;
    border-radius: 16px;
    padding: 15px;
}
.tab-cnt .frm > div > div {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 9px 0;
}
.tab-cnt .frm > div > div > div > div > div {
    background: #E1FFFE;
    border: 1px solid #0ABAB5;
    border-radius: 10px;
    overflow: hidden;
}
.tab-cnt .frm > div > div > div > div > div > div {
    font: 16px/24px var(--pm);
    color: #095553;
}
.tab-cnt .frm > div > div > div > div > div > div[role="button"]{ color: #92c4c2; transition: all ease 0.75s; margin-left: 5px; width: 30px; text-align: center; justify-content: center;}
.tab-cnt .frm > div > div > div > div > div > div[role="button"]:hover{background-color: #BAFFFD; color: #000;}

.tab-cnt .frm textarea {
    width: 100%;
    min-height: 200px;
    color: rgba(0, 0, 0, 0.4);
    font: 14px/21px var(--pm);
}
.tab-cnt .frm textarea::placeholder {
    color: rgba(0, 0, 0, 0.4);
}
.tab-content .send_btn, .btn-primary {
    margin-top: 30px;
    margin-left: auto;
    display: block;
    width: 150px;
    height: 48px;
    background: #0ABAB5;
    border: 1px solid #22A9A5;
    box-shadow: 0px 2px 5px 1px rgba(10, 186, 181, 0.26);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    font: 16px/24px var(--pm);
    color: #fff;
    transition: 0.5s all ease-in-out;
}
.tab-content .send_btn:hover,  .btn-primary:hover {
    background: #fff;
    color: #0ABAB5;
    border-color:#0ABAB5 ;
}
/*CREATE POST*/

/*CONNECT ACCOUNT*/
.connect_act .box {
    background: #FFFFFF;
    border: 1px solid #9CC2C1;
    border-radius: 16px;
    max-height: 565px;
    min-height: 565px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
}
.connect_act .row > div:last-child .box {
    justify-content: center;
}
.connect_act .box .add a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font: 14px/21px var(--pm);
    color: #000;
    text-decoration: none;
    transition: 0.5s all ease-in-out;
}
.connect_act .box .add a img {
    transition: 0.5s all ease-in-out;
}
.connect_act .box .add a:hover {
    color: var(--green);
}
.connect_act .box .add a:hover img {
    transform: scale(0.5);
}
.connect_act .box .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    border-bottom: 1px solid #9CC2C1;
}
.connect_act .box .top .rft {
    width: 100px;
}
.connect_act .box .top .lft {
    width: calc(100% - 100px);
    padding-right: 15px;
}
.connect_act .box .top .lft h5 {
    font: 14px/21px var(--pb);
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.connect_act .box .top .lft button {
    width: 150px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #0ABAB5;
    border-radius: 10px;
    color: #0ABAB5;
    font: 14px/21px var(--pm);
    transition: 0.5s all ease-in-out;
}
.connect_act .box .top .lft button:hover {
    color: #fff;
    background: #0ABAB5;
}
.connect_act .box .bottom {
    width: 100%;
    padding: 25px 0 25px;
    min-height: 40vh;
}
.connect_act .box .bottom h4 {
    font: 14px/21px var(--pb);
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.connect_act .box .bottom ul {
    padding: 0;
    margin: 0;
}
.connect_act .box .bottom ul li:not(:last-child) {
    margin-bottom: 10px;
}
.connect_act .box .bottom ul li a {
    font: 14px/21px var(--pm);
    color: #0ABAB5;
    text-decoration: none;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}
.connect_act .box .bottom ul li a img {width: 10px; margin-left: 4px;}
.connect_act .box .bottom ul li a::after {
    content: url(../images/ext.png);
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s all;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.1);
}
.connect_act .box .bottom ul li a:hover {
    color: #095553;
}
.connect_act .box .bottom ul li a:hover::after {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}
.connect_act .box .bottom .view_all {
    position: absolute;
    right: 5px;
    bottom: 10px;
    background: transparent;
    border: none;
    color: #333333;
    font: 14px/21px var(--pm);
    padding-right: 25px;
    transition: 0.5s all;
}
.connect_act .box .bottom .view_all::after {
    content: url(../images/r_arrow.png);
    position: absolute;
    right: 5px;
    top: 2px;
    transition: 0.5s all;
}
.connect_act .box .bottom .view_all:hover {
    color: #0ABAB5;
}
.connect_act .box .bottom .view_all:hover::after {
    content: url(../images/r_arrow_hover.png);
    transform: translateX(4px);
}
/*CONNECT ACCOUNT*/

.form-control{ height: 48px; border-radius: 10px; box-shadow: none !important; font-size: 14px;}
.form-control::-webkit-input-placeholder {color:    #9CC2C1;}
:-moz-placeho.form-controllder {color:    #9CC2C1; opacity:  1;}
.form-control::-moz-placeholder {color:    #9CC2C1; opacity:  1;}
.form-control:-ms-input-placeholder {color:    #9CC2C1;}
.form-control::-ms-input-placeholder {color:    #9CC2C1;}
.form-control::placeholder {color:    #9CC2C1;}
.searchicon{ background: url(../images/search.png) no-repeat; background-position: 96% center; padding-right: 48px;}
.form-control:focus{border-color: #9CC2C1;}
.checktxt{ display: flex; align-items: center;}
.checktxt label{ margin-right: 10px;}
.badge-primary {color: #0c4a49 !important;background: #c2fffd; border: 1px solid #75eeeb; font-weight: normal !important;text-transform: uppercase;letter-spacing: 0.5px; font-size: 10px !important;}
.btn-primary{width: auto; margin: 0px; display: flex; align-items: center;}
.btn-primary img{ margin-right: 5px; transition: all ease 0.75s;filter: brightness(0) invert(1);}
.btn-primary:hover img{ filter: none;}
.searchwithbtn{ display: flex; align-items: center; justify-content: flex-end;}
.searchwithbtn .btn{ margin-left: 30px;}
.searchwithbtn .form-control{width: auto;}

.filterarea{width: 375px; padding-right: 30px; margin-right: 30px; border-right: 1px solid rgba(122, 170, 169, 0.3);}
.filterarea .form-control{width: 100%;}
.filterarea .searchwithbtn{ margin-top: 15px;}
.swcoontent{display: flex}

.checktxtlist{ display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
.checktxtlist li{display: flex;}
.checktxtlist li label{ margin: 0px; width: 100%; cursor: pointer; display: flex; align-items: center; font-size: 14px; padding: 8px 0px;}
.checktxtlist li label::before{ margin-right: 15px;}
.checktxtlist li label img {margin-right: 5px; width: 20px;}
.togglebtn label {cursor: pointer;  width: 40px;height: 20px;background: #bcd7d6;display: inline-block;border-radius: 100px;position: relative;}
.togglebtn label:after {content: '';position: absolute;top: 5px;left: 5px;width: 10px;height: 10px;background: #fff;border-radius: 90px;transition: 0.3s;}
.togglebtn input + label::before{ display: none !important;}
.togglebtn input:checked + label {background: #0abab5;}
.togglebtn input:checked + label:after {left: calc(100% - 5px);transform: translateX(-100%);}
.togglebtn input[checked=""] + label {background: #0abab5;}
.togglebtn input[checked=""] + label:after {left: calc(100% - 5px);transform: translateX(-100%);}
.togglebtn label:active:after {width: 30px;}

.type_box > div > div .css-3w2yfm-ValueContainer > div > div > div:last-child:hover, .tab-cnt .frm > div > div > div > div > div > div[role="button"]:hover {
    background-color: #0abab5;
    color: #fff;
}

/*SOCIAL MEDIA SETTINGS*/
.social_settings .blk {
      width: 100%;
      margin-bottom: 10px;
      position: relative;
  }
  .social_settings .blk h4 {
      font: 14px/21px var(--pb);
      color: #000;
      text-transform: uppercase;
      position: static;
      left: 0;
      top: 0px;
      margin-bottom: 15px;
  }
  .social_settings .blk h4 img {
      width: 32px;
      height: 32px;
      margin-right: 10px;
  }
  .social_settings .blk .tabls {
      /* width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center; */
  }
  .social_settings .blk .tabls .bls {
      width: 50%;
      position: relative;
  }
  .tabls .bls form {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
  }
  .tabls .bls form input[type="submit"] {
      width: 75px;
      height: 48px;
      background: #0ABAB5;
      border: 1px solid #22A9A5;
      box-shadow: 0px 2px 5px 1px rgba(10, 186, 181, 0.26);
      backdrop-filter: blur(2px);
      border-radius: 0px 10px 10px 0px;
      font: 14px/21px var(--pb);
      color: #fff;
      text-transform: uppercase;
      transition: 0.5s all;
  }
  .tabls .bls form input[type="submit"]:hover {
      background: #fff;
      color: #0ABAB5;
  }
  .tabls .bls form input[type="text"] {
      max-width: 385px;
      height: 48px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      border-radius: 10px 0px 0px 10px;
      font: 14px/21px var(--pr);
      color: #111E1D;
      padding: 0 15px;
  }
  .tabls .bls form input[type="text"]::placeholder {
      color: #7AAAA9;
  }
  .social_settings .blk .tabls .bls .table-responsive {
      background: #DFF6FF;
      border: 1px solid #C7D9FF;
      border-radius:16px;
      padding: 10px;
  }
  /* .social_settings .blk .tabls .bls:last-child .table-responsive, .social_settings .blk:nth-child(2n) .tabls .bls:last-child .table-responsive {
      border-radius: 0 16px 16px 0;
      border-left-color: transparent;
  } */
  .social_settings .blk .tabls .bls .table-responsive table {
      border-collapse: separate;
      border-spacing: 0 5px;
  }
  .social_settings .blk .tabls .bls .table-responsive table thead tr th {
      font: 14px/21px var(--pm);
      color: #333333;
      border: none;
      padding: 5px 15px;
  }
  .social_settings .blk .tabls .bls .table-responsive table tbody tr td {
      font: 14px/21px var(--pm);
      color: #000;
      background: #FFFFFF;
      border: 1px solid #B9E3E2;
      border-radius: 0px;
      padding: 9px 15px;
  }
  .social_settings .blk .tabls .bls .table-responsive table tbody tr td:first-child {
      border-radius: 10px 0 0 10px;
  }
  .social_settings .blk .tabls .bls .table-responsive table tbody tr td:last-child {
      border-radius: 0 10px 10px 0;
  }
  .social_settings .blk .tabls .bls .table-responsive table tbody tr td:not(:last-child) {
      border-right-color: transparent;
  }
  .social_settings .blk .tabls .bls .table-responsive table tbody tr td:not(:first-child) {
      border-left-color: transparent;
  }
  .social_settings .blk .tabls .bls .table-responsive table tbody tr td button {
      border: none;
      background: transparent;
  }
  .switch_box {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40px;
      height: 20px;
  }
  .switch_box input {
      background: #fff;
      border: 1px solid #22A9A5;
      box-shadow: 0px 2px 5px 1px rgba(10, 186, 181, 0.26);
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      border-radius: 30px;
      display: block;
      position: relative;
      cursor: pointer;
      outline: none;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      width: 40px;
      height: 20px;
      appearance: none;
  }
  .switch_box input:checked {
      background: #22A9A5;
  }
  .switch_box input:checked::after {
      left: calc(100% - 1em);
      background: #fff;
  }
  .switch_box input::after {
      position: absolute;
      content: "";
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: #0abab5;
      box-shadow: 0 0 0.25em rgba(0,0,0,.3);
      transform: scale(.7);
      left: 4px;
      transition: all .2s ease-in-out;
      top: 2px;
  }
  .social_settings .blk:nth-child(2n) .tabls .bls .table-responsive {
      background: #EBF1FF;
      border: 1px solid #C7D9FF;
      border-radius: 16px 0 0 16px;
      border-right-color: transparent;
  }
  
  /*SOCIAL MEDIA SETTINGS*/

/*CONNECT MODAL*/
.connet_modal .modal-dialog {
    max-width: 1000px;
    overflow: hidden;
    border-radius: 30px;
    position: relative;
}
.connet_modal .modal-content {
    text-align: center;
    padding: 0;
    border-radius: 30px;
    background: #fff url(../images/popup_bg.png) no-repeat 5px center;
    background-size: cover;
    min-height: 400px;
}
.connet_modal .modal-header {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.connet_modal .modal-header .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    background-size: 12px;
}
.connet_modal .modal-header div {
    font: 35px/40px var(--pb);
    color: var(--black);
    margin-bottom: 30px;
}
.connet_modal .frm:not(:last-child) {
    margin-bottom: 20px;
}
.connet_modal .frm {
    width: 100%;
    max-width: 325px;
    margin: 0 auto;
    display: block;
    position: relative;
}
.connet_modal .frm input {
    width: 100%;
    border: 1px solid #9CC2C1;
    border-radius: 10px;
    height: 48px;
    background: var(--white);
    padding: 10px 25px;
    color: #7AAAA9;
}
.connet_modal .frm input::placeholder {
    color: transparent;
}
.connet_modal .frm label {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    font: 14px/21px var(--pr);
    color: #7AAAA9;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s all ease-in-out;
    background: var(--white);
    padding: 0 10px;
    z-index: 1;
}
.connet_modal .frm input:invalid, .connet_modal .frm input:focus, .connet_modal .frm input:not(:placeholder-shown) {
    border-color: var(--green);
    background: var(--white)!important;
}
.connet_modal .frm input:invalid ~ label, .connet_modal .frm input:focus ~ label, .connet_modal .frm input:not(:placeholder-shown) ~ label {
    top: 0;
    color: var(--green);
}
.connet_modal .frm .s_btn {
    width: 100%;
    text-align: center;
    background: var(--green);
    color: var(--white);
    font: 16px/24px var(--pb);
    height: 48px;
    border: 2px solid #0ABAB5;
    border-radius: 10px;
    position: relative;
}
.connet_modal .frm .s_btn:hover {
    transition: 0.5s;
    border-color: var(--green);
    background: transparent;
    color: var(--green);
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
}

.connet_modal .frm .sr_btn {
    width: 100%;
    text-align: center;
    color: var(--green);
    background: transparent;    
    font: 16px/24px var(--pb);
    height: 48px;
    border: 2px solid #0ABAB5;
    border-radius: 10px;
    position: relative;
}
.connet_modal .frm .sr_btn:hover {
    transition: 0.5s;
    border-color: var(--green);
    background: var(--green);
    color: var(--white);
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
}
.connet_modal .bgs {
    width: 48%;
    background: #fff;
    padding: 50px 0;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 #e1e1e1;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/*CONNECT MODAL*/

/*ERROR PAGE*/
.error {
    width: 100%;
    background: #fff;
    border-radius: 30px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 96.8vh;
}
.error > .img {
    text-align: center;
    margin-bottom: 50px;
}
.error > .img img {
    object-fit: contain;
    object-position: center;
    width: 40%;
    margin: auto;
}
.error .txt a {
    width: 200px;
    text-align: center;
    background: var(--green);
    color: var(--white);
    font: 16px/24px var(--pb);
    height: 48px;
    border: 2px solid #9CC2C1;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.error .txt a:hover {
    transition: 0.5s;
    border-color: var(--green);
    background: transparent;
    color: var(--green);
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
}
/*ERROR PAGE*/

/*BUTTON LOADING*/
.btn_loading span {
    opacity: 0;
    visibility: hidden;
}
.button_loading {
    width: 100%;
}
.btn_loading .button_loading::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #fff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}
.btn_loading:hover .button_loading::after {
    border-top-color: var(--green);
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
/*BUTTON LOADING*/

/*ERROR MESSAGE*/
span.error_msg {
    display: inline-flex;
    padding: 5px 15px;
    background: #ff7171;
    color: #fff;
    font: 14px/21px var(--pr);
    border-radius: 3px;
    border: 1px solid red;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 10px 0 0;
    width: 100%;
}
span.error_msg img {
    width: 16px;
    margin-right: 10px;
    margin-top: -4px;
}
/*ERROR MESSAGE*/


/*LOADER*/
.recentactivity .loading {
    position: relative;
    height: 300px;
}
.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    width: 48px;
    height: 48px;
    border: 3px dotted #176260;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 2s linear infinite;
    animation: rotation 2s linear infinite;
}
.loader::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted var(--green);
    border-style: solid solid dotted;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    -webkit-animation: rotationBack 1s linear infinite;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*LOADER*/

.loaderanim {border-radius: 50%; background: #afcccb;}
.loaderanim svg{filter: brightness(0) invert(1);}

/* Custom css by sen */
.tabls .bls form button[type="submit"].custom_btn {
    width: 75px;
    height: 48px;
    background: #0ABAB5;
    border: 1px solid #22A9A5;
    box-shadow: 0px 2px 5px 1px rgba(10, 186, 181, 0.26);
    backdrop-filter: blur(2px);
    border-radius: 0px 10px 10px 0px;
    font: 14px/21px var(--pb);
    color: #fff;
    text-transform: uppercase;
    transition: 0.5s all;
}
.gpt_btn.btn_loading .button_loading::after{
	border-top-color: rgb(10, 186, 181);
}
.gpt_btn.btn_loading:hover .button_loading::after{
	border-top-color: #FFF;
}

.social_settings .posthistoryloader .loading{
    top: 45px;
}

/* End - Custom css by sen */

.flex-0{flex: 0;}
.csitxt{ display: flex; flex-direction: column; align-items: center; justify-content: center; height: calc(100vh - 310px);}

.modal.show {animation: fadeIn 0.75s ease-in both;}
@keyframes fadeIn {
from {opacity: 0;transform: translate3d(0, -5%, 0);}
to {opacity: 1;transform: translate3d(0, 0, 0);}
}
.greenbg{background: #DFF6FF; border: 1px solid #C7D9FF; border-radius: 16px;}
.social_settings .blk .tabls .bls .greenbg .table-responsive{ background: transparent; border: none; border-radius: 0px;}
.social_settings .blk .tabls .bls .greenbg form{ padding: 10px; margin-bottom: 0px; justify-content: center;}
.css-t3ipsp-control:hover{ border-color: #0ABAB5 !important;}

.d-close{position: absolute; right: 5px; top: 5px; width: 28px; height: 28px; border-radius: 50%; display: flex; cursor: pointer; align-items: center; justify-content: center; padding: 8px; transition: all ease 0.75s;}
.d-close:hover{background: #9cc2c1;}
.retwit {flex-direction: row;display: flex;width: 100% !important;}
.retwituser {width: 135px;}
.extlinks{ padding: 5px;}
.cm-bg-none .modal-content{ background: transparent; width: auto; min-height: auto;}
.cm-bg-none .bgs{width: 32rem; padding: 50px 30px;min-height: auto;}
.cm-bg-none .modal-dialog{ max-width: inherit;display: flex; align-items: center; justify-content: center;}
.cm-bg-none .modal-body{ width: 100%;}
.btn-close:focus{ box-shadow: none;}
.ptflex {display: flex;flex-direction: row;align-items: center;justify-content: center;}
.ptflex h3 + span{ margin-left: 15px; font-weight: 600;}

/*POST IMAGES*/
.rec_box .boxs .media_txt ._txt .post_img {
      width: 100%;
      overflow: hidden;
      border-radius: 15px;
  }
  .rec_box .boxs .media_txt ._txt .post_img ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
  }
  .rec_box .boxs .media_txt ._txt .post_img ul li {
      width: 24.5%;
      margin: 0;
  }
  .rec_box .boxs .media_txt ._txt .post_img ul li:not(:last-child) {
      margin-right: 8px;
  }
  .rec_box .boxs .media_txt ._txt .post_img ul li a img {
      width: 100%;
      max-width: 328.5px;
      max-height: 300px;
      min-height: 300px;
      object-fit: cover;
  }
  .rec_box .boxs .media_txt ._txt ul li:last-child img {
      border-radius: 0 15px 15px 0;
  }

  /*POST IMAGES*/

  /* .like_btn
  {
    display:flex;
    float: left;
    gap: 15px;
  } */
  .like_repost_box
  {
    margin-top: 15px;
    display: flex;
    align-items: initial;
    justify-content: space-between;
  }
  .likebox_panel, .repostbox_panel
  {
    width: 49%;
    padding: 12px;
    transition: 0.5s all ease-in-out;
  }
  .likebox_panel .gpt_btn , .repostbox_panel .gpt_btn
  {
    float: left;
    margin-bottom: 12px;
  }

  .likebox_panel, .repostbox_panel {
    border: 1px solid #22a9a5;
    border-radius: 10px;
    background: #fff;
}
.like_repost_box .type_box {
    flex-wrap: wrap;
    margin-top: 15px;
}
button.like_btn {
    width: max-content;
    height: 40px;
    padding: 10px 10px 10px 5px;
    background: #fff;
    border: 1px solid #22a9a5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
button.like_btn img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 100%;
    transition: 0.5s all ease-in-out;
}
button.like_btn img + span {
    font: 16px/24px var(--pm) !important;
    color: #22A9A5 !important;
    padding-left: 5px;
    transition: 0.5s all ease-in-out;
    opacity: 1;
    visibility: visible;
}

button.like_btn:hover {
    background: #22a9a5;
}
button.like_btn:hover img + span {
    color: #fff !important;
}
button.like_btn:hover img {
    background: #FFF;
}
.recent_likes {
    width: 100%;
    margin-top: 10px;
}
.recent_likes > span > img {
    width: 20px;
    object-fit: contain;
    margin-right: 5px;
}
.recent_likes ul {
    margin: 10px 0 0 !important;
    flex-wrap: wrap;
}
.recent_likes ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.recent_likes ul li img {
    margin-right: 5px;
    width: 17px;
    background: #fff;
    border: 1px solid #24aaa6;
    border-radius: 100% !important;
    object-fit: contain;
    object-position: center;
}
.likebox_panel > h4, .repostbox_panel > h4 {
    font: 18px/24px var(--pb) !important;
    margin-bottom: 10px !important;
    text-transform: uppercase;
    text-align: center;
}
.recent_likes > span.title span {
    font: 14px/24px var(--pb) !important;
}
.errbox
{
    color: red;
    margin-left: 9px;
    font-size: 13px;
    font-weight: 500;
}