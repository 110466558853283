/*RESPONSIVE*/

@media screen and (max-width: 1610px) {
      /*LOGIN PAGE*/
      .container {padding: 0 10px!important;}
      .login_page .text h1 span {font-size: 120px;line-height: 140px;}
      .login_page .text h1 {font-size: 70px;line-height: 90px;}
      /*LOGIN PAGE*/
      
      /*DASHBOARD PAGE*/
      .dash_box .box > .img {width: 65px;}
      .dash_box .box > .txt {width: calc(100% - 65px)}
      /*DASHBOARD PAGE*/
      
      /*CONNECT ACCOUNT*/
      .connect_act .box .top .rft {width: 75px;}
      /*CONNECT ACCOUNT*/
  }
  
  @media screen and (max-width: 1560px) {
      /*LOGIN PAGE*/
      .login_page .log_form {max-width: 600px;}
      .login_page {padding: 100px 85px;}
      .login_page .text {padding-right: 25px;}
      /*LOGIN PAGE*/
      
      /*DASHBOARD PAGE*/
      .dash_box .box > .txt p {min-height: 42px;}
      /*DASHBOARD PAGE*/
      
      /*CONNECT ACCOUNT*/
      .connect_act .box .top .rft {width: 50px;}
      /*CONNECT ACCOUNT*/
      
      /*SOCIAL MEDIA SETTINGS*/
      .social_settings .blk .tabls {flex-direction: column;}
      .social_settings .blk .tabls .bls:first-child {margin-bottom: 10px;}
      .social_settings .blk .tabls .bls {width: 100%;}
      .social_settings .blk .tabls .bls .table-responsive, .social_settings .blk .tabls .bls:last-child .table-responsive, .social_settings .blk:nth-child(2n) .tabls .bls:last-child .table-responsive, .social_settings .blk:nth-child(2n) .tabls .bls .table-responsive {border-radius: 16px;}
      /*SOCIAL MEDIA SETTINGS*/
  }
  
  @media screen and (max-width: 1440px) {
      /*CONNECT ACCOUNT*/
      .connect_act .box .top .lft button {width: 120px;font-size: 12px;}
      /*CONNECT ACCOUNT*/
  }
  
  @media screen and (max-width: 1360px) {
      /*LOGIN PAGE*/
      .login_page {padding: 100px 60px;}
      .login_page .log_form {max-width: 575px;}
      .login_page .text h1 {font-size: 60px;line-height: 75px;}
      .login_page .text h1 span {font-size: 100px;line-height: 120px;}
      .login_page .text p {font-size: 26px;line-height: 50px;}
      /*LOGIN PAGE*/
      
      /*DASHBOARD PAGE*/
      .dash_box .box {padding: 15px;}
      .dash_box .box > .img {width: 50px;}
      .dash_box .box > .txt h2 {font-size: 35px;line-height: 35px;}
      /*DASHBOARD PAGE*/
      
      /*CONNECT ACCOUNT*/
      .connect_act .box .top {align-items: flex-start;}
      .connect_act .box .top .rft {width: 30px;}
      /*CONNECT ACCOUNT*/
  }
  
  @media screen and (max-width: 1200px) {
      body{ padding: 5px;}
      .sidebar .logo img {max-width: 165px;}
      .sidebar .logo{text-align: left;}
      /*LOGIN PAGE*/
      .login_page {padding: 75px 50px;}
      .login_page .log_form {max-width: 485px;padding: 100px 10px;}
      .log_form .logo {margin-bottom: 50px;}
      .login_page .text h1 {font-size: 50px;line-height: 65px;margin-bottom: 0;}
      .login_page .text h1 span {font-size: 85px;line-height: 110px;}
      .login_page .text p {font-size: 22px;line-height: 35px;margin: 0;}
      /*LOGIN PAGE*/
      
      /*DASHBOARD PAGE*/
      button.menu, button.close {display: flex;}
      button.menu{left: 20px; top: 20px;}
      .sidebar {left: -100%;z-index: 1000;background: rgba(23, 102, 99, 0.97);top: 0;border-radius: 16px 0 0 16px; padding: 15px; width: 300px;}
      div#open {left: 0;}
      div#open button.menu {display: none;}
      .das_wrapper {width: 100%;padding: 15px;}
      .dashboard header {padding-left: 50px;}
      /*DASHBOARD PAGE*/
      
      /*CONNECT ACCOUNT*/
      .connect_act .box .bottom {min-height: 46vh;}
      /*CONNECT ACCOUNT*/
      button.close{right: 15px;}
  }
  
  @media screen and (max-width: 1024px) {
      /*LOGIN PAGE*/
      .login_page .flex {flex-direction: column;}
      .login_page {padding: 50px;}
      .login_page .text {padding: 0 0 35px 0;width: 100%;text-align: center;}
      .login_page .text h1 {font-size: 40px;line-height: 45px;}
      .login_page .text h1 span {font-size: 70px;line-height: 90px;}
      /*LOGIN PAGE*/
  }
  
  @media screen and (max-width: 991px) {
    .swcoontent{ flex-direction: column;}
    .filterarea {width: 100%;padding: 15px; margin-right: 0; border: 1px solid rgba(122, 170, 169, 0.3); border-radius: 16px; margin-bottom: 30px; background: #f8ffff;}
    .rec_box .boxs .media_txt ._txt p{ font-size: 12px;}
    .rec_box .boxs .media_txt ._txt h4, .rec_box .boxs .media_txt ._txt button.gpt_btn span{ font-size: 14px;}
    .rec_box .boxs .media_txt ._txt span{ font-size: 10px;}
    
  }
  
  @media screen and (max-width: 851px) {
      /*DASHBOARD PAGE*/
      .dash_box .row .col-md-3 {width: 50%;}
      .dash_box .box {margin-bottom: 15px;}
      .dashboard header .rft {width: 30%;}
      /*DASHBOARD PAGE*/
      
      /*CONNECT ACCOUNT*/
      .connect_act .row > div {width: 50%;margin-bottom: 25px;}
      /*CONNECT ACCOUNT*/
  }
  
  @media screen and (max-width: 767px) {
      /*LOGIN PAGE*/
      .login_page .log_form {max-width: 500px;padding: 75px 10px;}
      .log_form .logo {margin-bottom: 40px;}
      .log_form form h3 {font-size: 30px;line-height: 30px;margin-bottom: 50px;}
      /*LOGIN PAGE*/
      
      /*DASHBOARD PAGE*/
      .dashboard header .flex {flex-direction: column-reverse;}
      .dashboard header .rft {width: 100%;}
      .dashboard header {padding-left: 5px;}
      .main_content {max-height: 77vh;min-height: 77vh;}
      /*DASHBOARD PAGE*/
      
      /*RECEIVED ACTIVITY*/
      .dashboard header .lft h3 {font-size: 26px;line-height: 40px;}
      .rec_box {padding: 20px;}
      .type_box {flex-direction: column;}
      .type_box > div {width: 100%;}
      .type_box > div > div {border-radius: 15px;}
      .type_box button.send_btn {width: 100%;border-radius: 15px;margin-top: 10px;}
      /*RECEIVED ACTIVITY*/
      
      /*SOCIAL MEDIA SETTINGS*/
      .social_settings .blk .tabls .bls .table-responsive table {width: 767px;}
      .error > .img img {width: 80%;}
      /*SOCIAL MEDIA SETTINGS*/
      .dashboard .lft {width: 100%; text-align: center; margin-top: 15px;}
      .searchwithbtn{ margin-top: 15px;}
      .searchwithbtn .form-control {width: auto; flex: 1;}
      .searchwithbtn .btn {margin-left: 15px;}
      .connet_modal .bgs {width: 70%;}
      .cm-bg-none .bgs{width: 32rem;}
      .retwit{ flex-direction: column;}
      .retwituser{ width: 100%; margin-bottom: 15px;}
      .ptflex{ flex-direction: column;}
      .ptflex h3 + span {margin-left: 0px;}
      .like_repost_box {flex-direction: column;}
      .likebox_panel, .repostbox_panel {width: 100%;}
      .likebox_panel {margin-bottom: 10px;}
  }
  
  @media screen and (max-width: 660px) {
      /*LOGIN PAGE*/
      .login_page .text h1 {font-size: 35px;line-height: 40px;}
      .login_page .text h1 span {font-size: 60px;line-height: 85px;}
      .login_page .text p {font-size: 20px;line-height: 30px;}
      .log_form .logo img {max-width: 220px;}
      .log_form .logo {margin-bottom: 35px;}
      /*LOGIN PAGE*/
      
      /*DASHBOARD PAGE*/
      .post_history .table {width: 767px;}
      /*DASHBOARD PAGE*/
      
  }
  
  @media screen and (max-width: 560px) {
      /*LOGIN PAGE*/
      .login_page {padding: 50px 25px;}
      .rec_box .boxs .media_txt ._txt button.gpt_btn img {width: 25px;}
      .rec_box .boxs .media_txt ._txt button.gpt_btn {padding: 4.5px 6px;}
      .rec_box .boxs .media_txt ._txt button.gpt_btn span {font-size: 13px;margin-left: 4px;}
      /*LOGIN PAGE*/
      
      /*CREATE POST*/
      .create_post .nav-tabs li button {padding: 15px 15px 15px 35px;}
      .connet_modal .bgs {width: 100%;    padding: 30px 0;border-radius: 10px;}
      .cm-bg-none .bgs{ padding: 50px 30px;}
      .connet_modal .modal-header div {font-size: 26px;line-height: 30px;margin-bottom: 15px;}
      /*CREATE POST*/
      
  }
  
  @media screen and (max-width: 480px) {
      /*LOGIN PAGE*/
      .login_page {padding: 35px 15px;}
      .login_page .text h1 {font-size: 30px;line-height: 35px;}
      .login_page .text h1 span {font-size: 50px;line-height: 75px;}
      .login_page .text p {font-size: 18px;line-height: 28px;}
      .login_page .text {padding: 0 0 30px 0;}
      .login_page .log_form {max-width: 500px;padding: 50px 25px;}
      .log_form .logo img {max-width: 160px;}
      .log_form .logo {margin-bottom: 30px;}
      .log_form form h3 {margin-bottom: 35px;font-size: 26px;}
      /*LOGIN PAGE*/
      
      /*DASHBOARD PAGE*/
      .dash_box .row .col-md-3 {width: 100%;}
      .dashboard header .lft h4 {font-size: 20px;line-height: 30px;}
      .dashboard .lft {padding-top: 10px;}
      .main_content {max-height: 75vh;min-height: 75vh;}
      .gene-layout, main_content  {border-radius: 20px;}
      /*DASHBOARD PAGE*/
      
      /*RECEIVED ACTIVITY*/
      .rec_box {padding: 10px;}
      .rec_box .boxs .meida_icons {width: 35px;margin-bottom: 20px;}
      .rec_box .boxs {flex-direction: column;}
      .rec_box .boxs .media_txt {width: 100%;margin-left: 0;position: relative; padding: 10px;}
      .rec_box .boxs .media_txt::before {content: "";position: absolute;left: 10px;top: -21px;border: 10px solid #c8d4d4;border-top-color: transparent;border-right-color: transparent;border-left-color: transparent;}
      .rec_box .boxs._fb .media_txt::before {border-bottom-color: #C7D9FF;}
      .rec_box .boxs._twt .media_txt::before {border-bottom-color: #c7d9ff;}
      .rec_box .boxs._insta .media_txt::before {border-bottom-color: #ffcac2;}
  
      /*RECEIVED ACTIVITY*/
      
      /*CREATE POST*/
      .create_post .nav-tabs {flex-direction: column;}
      .create_post .nav-tabs li {width: 100%;margin-bottom: 10px;}
      .create_post .nav-tabs li button {width: 100%;text-align: left;padding: 15px 20px 15px 50px;border: 1px solid #9CC2C1;border-radius: 10px;}
      .create_post .tab-content {border-radius: 10px;padding: 15px;min-height: 46vh;}
      .create_post .nav-tabs li button.active, .create_post .nav-tabs li button:hover {border: 1px solid #9CC2C1;border-radius: 10px;border-bottom-color: transparent;background-color: #bbe9e8;color: #242424;}
      /*CREATE POST*/
      
      /*CONNECT ACCOUNT*/
      .connect_act .row > div {width: 100%;}
      .connect_act .box {min-height: 460px;max-height: 46px;}
      .error > .img img {width: 100%;}
      .error {padding: 85px 50px;}
      /*CONNECT ACCOUNT*/
      .searchwithbtn { flex-direction: column-reverse;}
      .searchwithbtn .btn{ margin-bottom: 15px; margin-top: 0px; width: 100%; margin-left: 0px;}
      .searchwithbtn .form-control{ width: 100%; flex: auto;}
      .dashboard header .lft h3 {font-size: 22px;}
      .rec_box .boxs .media_txt ._txt > p {margin-left: -45px;}
      button.like_btn {width: 100%;justify-content: center;height: 50px;}
      .type_box > div > div {padding: 6px 10px;}
      .type_box button.send_btn {height: 50px;}
  }
  
  @media screen and (max-width: 380px) {
  }
  
  /*RESPONSIVE*/